import styled from "styled-components";

export const Container = styled.figure`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 35px;

  @media (max-width: 500px) {
    height: 100%;
    gap: 10px;
    margin-bottom: 20px;
  }
`;

export const Logo = styled.img`
  &:nth-child(1) {
    width: 156px;
    height: 77px;
  }

  &:nth-child(2) {
    width: 173px;
    height: 68px;
  }

  @media (max-width: 475px) {
    &:nth-child(1) {
      width: 113px;
      height: 56px;
    }

    &:nth-child(2) {
      width: 100px;
      height: 63px;
    }
  }
`;
