import styled from "styled-components";

import Background from "../../assets/beach_tennis_masculino/background.svg";
import BackgroundMobile from "../../assets/beach_tennis_masculino/background_mobile.svg";

export const Container = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-position: top right;

  @media (max-width: 475px) {
    background-image: url(${BackgroundMobile});
    background-repeat: no-repeat;
    background-position: top right;
  }
`;

export const Content = styled.section`
  width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  @media (max-width: 475px) {
    width: 100%;
    align-items: center;
    padding: 10px 10px;
    height: 100%;
    margin-top: 15px;
  }
`;

export const Form = styled.form`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerButton = styled.div`
  width: 300px;
  margin-top: 10px;
`;
