import { Container } from "./styles";

import LogoCaioba from "../../assets/beach_tennis_masculino/logo.svg";
import LogoSponsor from "../../assets/beach_tennis_masculino/patrocinador.svg";
import Logos from "../logos";

const InfosEvent = ({ name }) => {
  return (
    <Container>
      <label>Inscrição para o </label>
      <label>TORNEIO DE BEACH TENNIS</label>
      <label>{name}</label>
      <Logos LogoCaioba={LogoCaioba} LogoSponsor={LogoSponsor} />
      <label>VEM DE BEACH!</label>

      <label>Não esqueçam de levar suas próprias raquetes!</label>

      <label>
        Caso não consiga participar por algum motivo, avise a organização do
        evento!
      </label>
    </Container>
  );
};

export default InfosEvent;
