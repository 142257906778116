import { Container, Logo } from "./styles";

const Logos = ({ LogoCaioba, LogoSponsor }) => {
  return (
    <Container>
      <Logo src={LogoCaioba} alt="logo_caioba" />
      <Logo src={LogoSponsor} alt="logo_patrocinador" />
    </Container>
  );
};

export default Logos;
