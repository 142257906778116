import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { TextField } from "@mui/material";

import LoadingForm from "../../components/loadingForm";
import Button from "../../components/button";
import MaskedTextField from "../../components/maskedField";
import ContainerDisabled from "../../components/containerDisabled";

import {
  Container,
  ContainerInformations,
  Content,
  ContentInfos,
  Form,
  Informations,
  Logo,
  Logos,
} from "./styles";

import LogoCaioba from "../../assets/desafio-pai-e-filho/logo.svg";
import LogoVockan from "../../assets/desafio-pai-e-filho/logoVockan.svg";

const FatherSonChallenge = () => {
  const { control, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);

  const [available, setAvailabe] = useState(true);

  const validateLength = (value, length) => {
    return value.replace(/[^0-9]/g, "").length === length;
  };

  const onSubmit = async (formData) => {
    setLoading(true);

    const dataForm = {
      ...formData,
      eventName: "Desafio Pai e Filho",
    };

    try {
      const response = await fetch(
        "https://caiobasc.com.br/sendForm/formRegister.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataForm),
        }
      );

      const data = await response.json();

      if (data.success === false) {
        setLoading(false);
        Swal.fire({
          title: "Erro ao enviar formulário",
          html: data.message,
          icon: "error",
        }).then(() => {
          window.location.reload();
        });

        return;
      }

      setLoading(false);

      Swal.fire({
        title: "Formulário enviado com sucesso!",
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: `Erro ao enviar formulário: ${error}`,
        html: "Não foi possível enviar o formulário",
        icon: "error",
      }).then(() => {
        window.location.reload();
      });
    }
  };

  const verify = async () => {
    setLoading(true);

    const verifyData = {
      eventName: "Desafio Pai e Filho",
    };

    try {
      const response = await fetch(
        "https://caiobasc.com.br/sendForm/verify.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(verifyData),
        }
      );

      const data = await response.json();

      if (data.registration_closed === false) {
        console.log("Há vagas disponíveis:", data.spots_left);
      } else {
        console.log("Mensagem:", data.message);
        setAvailabe(false);
      }
    } catch (error) {
      console.log("Erro:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    verify();
  }, []);

  return (
    <Container>
      {loading && <LoadingForm />}

      <ContainerInformations>
        <Informations>
          <ContentInfos>
            <label>
              Inscrição para o <br />
              <span>DESAFIO PAI E FILHO</span>
            </label>
            <Logos>
              <Logo src={LogoCaioba} alt="logo_caioba" />
              <Logo src={LogoVockan} alt="logo_evoluir" />
            </Logos>
            <label>
              <strong>Data:</strong> Sábado 21/09
            </label>
            <label>
              <strong>Horário:</strong> 17h00
            </label>
            <label>
              Nosso parceiro da Vockan preparou um desafio para você e seu filho
              resolverem juntos!
            </label>
          </ContentInfos>
        </Informations>
        <Content>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {available === false ? (
              <ContainerDisabled />
            ) : (
              <>
                <Controller
                  control={control}
                  name="name"
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      required
                      label="Nome Completo do Jogador"
                      id="Nome Completo do Jogador"
                      variant="standard"
                      value={value}
                      onChange={onChange}
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="email"
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      label="Email"
                      required
                      variant="standard"
                      value={value}
                      onChange={onChange}
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="phone"
                  rules={{
                    required: true,
                    validate: (value) =>
                      validateLength(value, 11) || "Telefone incompleto",
                  }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <MaskedTextField
                      mask="(99) 99999-9999"
                      label="Telefone"
                      variant="standard"
                      value={value}
                      onChange={onChange}
                      fullWidth
                      sx={{ mb: 2 }}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="cpf"
                  rules={{
                    required: true,
                    validate: (value) =>
                      validateLength(value, 11) || "CPF incompleto",
                  }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <MaskedTextField
                      mask="999.999.999-99"
                      label="CPF"
                      variant="standard"
                      value={value}
                      onChange={onChange}
                      fullWidth
                      sx={{ mb: 2 }}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />

                <Button type="submit" backgroundColor="#0039FF" width={300}>
                  CONFIRMAR
                </Button>
              </>
            )}
          </Form>
        </Content>
      </ContainerInformations>
    </Container>
  );
};

export default FatherSonChallenge;
