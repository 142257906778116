import { Container } from "./styles";

const Button = ({ onClick, children, backgroundColor, disabled, width }) => {
  return (
    <Container
      onClick={onClick}
      style={{ backgroundColor: backgroundColor, width: width }}
      disabled={disabled}
    >
      {children}{" "}
    </Container>
  );
};

export default Button;
