import { FaTimes } from "react-icons/fa";
import { Container, Content, Navigation } from "./styles";

import SideBarItem from "./sideBarItem";

const SideBar = ({ active }) => {
  const closeSideBar = () => {
    if (active) {
      active(false);
    }
  };

  const handleNavLink = (event) => {
    event.preventDefault();
    const sectionID = event.target.getAttribute("href");
    const section = document.querySelector(sectionID);

    if (section) {
      closeSideBar();
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Container sidebar={active ? "true" : "false"}>
      {active && <FaTimes onClick={closeSideBar} />}
      <Content>
        <Navigation>
          <SideBarItem
            href="#inscricoes"
            onClick={(event) => handleNavLink(event)}
          >
            Inscrições
          </SideBarItem>
        </Navigation>
        <Navigation>
          <SideBarItem
            href="#sobrenos"
            onClick={(event) => handleNavLink(event)}
          >
            Sobre o Caioba
          </SideBarItem>
        </Navigation>
        <Navigation>
          <SideBarItem
            href="#linhadotempo"
            onClick={(event) => handleNavLink(event)}
          >
            Linha do Tempo
          </SideBarItem>
        </Navigation>
        <Navigation>
          <SideBarItem
            href="#contato"
            onClick={(event) => handleNavLink(event)}
          >
            Contato
          </SideBarItem>
        </Navigation>
      </Content>
    </Container>
  );
};

export default SideBar;
