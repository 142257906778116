import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

import {
  Container,
  ContainerInput,
  Content,
  ContentDescription,
  ContentForm,
  ContentTitle,
  Form,
  Header,
  Logo,
  Logos,
  Title,
  TitleForm,
  Warning,
} from "./styles";

import MaskedTextField from "../../components/maskedField";
import Button from "../../components/button";
import ContainerDisabled from "../../components/containerDisabled";
import LoadingForm from "../../components/loadingForm";

import LogoCaioba from "../../assets/trainingEvolves/logo.svg";
import LogoEvolves from "../../assets/trainingEvolves/logo_evolves.png";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const TrainingEvolves = () => {
  const [loading, setLoading] = useState(false);
  const [schedules, setSchedules] = useState([
    "Sábado: 09:00 às 09:40",
    "Sábado: 09:40 às 10:20",
    "Sábado: 10:20 às 11:00",
    "Sábado: 15:00 às 15:40",
    "Sábado: 15:40 às 16:20",
    "Sábado: 16:20 às 17:00",
    "Domingo: 10:00 às 10:40",
    "Domingo: 10:40 às 11:20",
    "Domingo: 11:20 às 12:00",
    "Domingo: 12:00 às 12:40",
  ]);
  const [available, setAvailabe] = useState(true);

  const {
    handleSubmit,
    register,
    watch,
    control,
    unregister,
    formState: { errors },
  } = useForm();

  const style = {
    mb: 2,
    "& .MuiInput-underline:before": {
      borderBottomColor: "#008781",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: "#008781",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#008781",
    },
  };

  const sportFrequency = [
    "Sim, 5x por semana",
    "Sim, 4x por semana",
    "Sim, 3x por semana",
    "Sim, 2x por semana",
    "Sim, 1x por semana",
    "Raramente",
    "Não",
  ];

  const eventIdToSchedule = {
    13: "Sábado: 09:00 às 09:40",
    14: "Sábado: 09:40 às 10:20",
    15: "Sábado: 10:20 às 11:00",
    16: "Sábado: 15:00 às 15:40",
    17: "Sábado: 15:40 às 16:20",
    18: "Sábado: 16:20 às 17:00",
    19: "Domingo: 10:00 às 10:40",
    20: "Domingo: 10:40 às 11:20",
    21: "Domingo: 11:20 às 12:00",
    22: "Domingo: 12:00 às 12:40",
  };

  const filterID = (string) => {
    const firstSpaceIndex = string.indexOf(" ");
    return firstSpaceIndex !== -1 ? string.substring(firstSpaceIndex + 1) : "";
  };

  const onSubmit = async (formData) => {
    setLoading(true);

    const dataForm = {
      ...formData,
      eventName: "Treino Evoluir",
      id: filterID(formData.time),
    };

    try {
      const response = await fetch(
        "https://caiobasc.com.br/sendForm/formRegister.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataForm),
        }
      );

      const data = await response.json();

      if (data.success === true) {
        Swal.fire({
          title: "Formulário enviado com sucesso!",
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          title: "Erro ao enviar formulário",
          html: data.error,
          icon: "error",
        }).then(() => {
          window.location.reload();
        });
        return;
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const validateLength = (value, length) => {
    return value.replace(/[^0-9]/g, "").length === length;
  };

  const verify = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        "https://caiobasc.com.br/sendForm/verify.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ eventName: "Treino Evoluir" }),
        }
      );

      const data = await response.json();

      const availableEventIds = data
        .filter((schedule) => !schedule.registration_closed)
        .map((schedule) => schedule.event_id);

      const availableSchedules = Object.entries(eventIdToSchedule)
        .filter(([eventId]) => availableEventIds.includes(Number(eventId)))
        .map(([, schedule]) => schedule);

      if (availableSchedules.length === 0) {
        setAvailabe(false);
      }

      setSchedules(availableSchedules);
    } catch (error) {
      console.log("Erro:", error);
    } finally {
      setLoading(false);
    }
  };

  const verifyFrequencySport = watch("sportsFrequency");
  useEffect(() => {
    if (verifyFrequencySport === "Não") {
      unregister("sport");
    }
  }, [verifyFrequencySport, unregister]);

  useEffect(() => {
    verify();
  }, []);

  return (
    <Container>
      {loading && <LoadingForm />}
      <Content>
        <Header>
          <ContentTitle>
            <Title>
              <label>Inscrição para a</label>

              <label>EVOLUIR PERFORMANCE</label>
            </Title>
            <Logos>
              <Logo src={LogoCaioba} alt="logo_caioba" />
              <Logo src={LogoEvolves} alt="logo_evoluir" />
            </Logos>
          </ContentTitle>

          <ContentDescription>
            <p>
              A <strong>Evoluir Performance</strong> é um centro físico de
              treinamento focado em aumento de performance, prevenção de lesões
              e condicionamento físico.
            </p>
            <br />
            <p>
              Venha fazer uma aula conosco durante o{" "}
              <strong>Caioba Soccer Camp no Club Med Lake Paradise</strong>!
            </p>
          </ContentDescription>
        </Header>

        <ContentForm>
          {available === false ? (
            <ContainerDisabled />
          ) : (
            <>
              <TitleForm>Bora marcar a aula?!</TitleForm>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <ContainerInput>
                  <TextField
                    required
                    label="Nome Completo"
                    variant="standard"
                    fullWidth
                    sx={style}
                    name="name"
                    {...register("name")}
                  />
                  <TextField
                    required
                    label="Email"
                    variant="standard"
                    fullWidth
                    sx={style}
                    name="email"
                    helperText={errors.email ? errors.email.message : null}
                    error={!!errors.email}
                    {...register("email", {
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Endereço de email inválido",
                      },
                    })}
                  />
                </ContainerInput>
                <ContainerInput>
                  <Controller
                    control={control}
                    name="phone"
                    rules={{
                      required: true,
                      validate: (value) =>
                        validateLength(value, 11) || "Telefone incompleto",
                    }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <MaskedTextField
                        mask="(99) 99999-9999"
                        label="Telefone"
                        variant="standard"
                        value={value}
                        onChange={onChange}
                        fullWidth
                        sx={style}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />

                  <TextField
                    required
                    label="Data de nascimento"
                    variant="standard"
                    fullWidth
                    type="date"
                    sx={style}
                    name="birthdate"
                    {...register("birthdate")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </ContainerInput>
                <ContainerInput style={{ marginBottom: 70 }}>
                  <TextField
                    required
                    label="Profissão"
                    variant="standard"
                    fullWidth
                    sx={style}
                    name="profession"
                    {...register("profession")}
                  />
                  <TextField
                    required
                    label="Endereço"
                    variant="standard"
                    fullWidth
                    sx={style}
                    name="address"
                    {...register("address")}
                  />
                </ContainerInput>
                <ContainerInput>
                  <FormControl fullWidth required variant="standard" sx={style}>
                    <InputLabel>Você pratica esportes?</InputLabel>
                    <Select
                      name="sportsFrequency"
                      {...register("sportsFrequency")}
                      label="Você pratica esportes?"
                    >
                      {sportFrequency.map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {verifyFrequencySport !== "Não" && (
                    <>
                      <TextField
                        required
                        label="Qual esporte?"
                        variant="standard"
                        fullWidth
                        sx={style}
                        name="sport"
                        {...register("sport")}
                      />
                    </>
                  )}
                </ContainerInput>

                <ContainerInput style={{ marginBottom: 50 }}>
                  <FormControl fullWidth sx={style} required variant="standard">
                    <InputLabel>Selecione um horário</InputLabel>
                    <Select
                      name="time"
                      {...register("time")}
                      label="Você pratica esportes?"
                    >
                      {schedules.map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Warning>
                    <label>IMPORTANTE</label> <br />
                    <label>Apenas 1 inscrição por pessoa.</label> <br />
                    <label>
                      Se atente aos hórarios das outras atividades (treino dos
                      craques, Jogos dos Pais, etc)!
                    </label>
                  </Warning>
                </ContainerInput>

                <Button backgroundColor="#BF28A9" type="submit" width={300}>
                  Confirmar
                </Button>
              </Form>
            </>
          )}
        </ContentForm>
      </Content>
    </Container>
  );
};

export default TrainingEvolves;
