import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import MaskedTextField from "../../components/maskedField";
import LoadingForm from "../../components/loadingForm";
import Button from "../../components/button";
import ContainerDisabled from "../../components/containerDisabled";

import { TextField } from "@mui/material";

import {
  Container,
  ContainerButton,
  ContainerInformations,
  Content,
  ContentInfos,
  ContentTitle,
  Form,
  Informations,
  Logo,
  Logos,
  TextYoga,
} from "./styles";
import Swal from "sweetalert2";

import LogoCaioba from "../../assets/yoga/logo.svg";
import LogoYogini from "../../assets/yoga/logo_yogini.svg";

const Yoga = () => {
  const { control, handleSubmit } = useForm();

  const [loading, setLoading] = useState(false);
  const [available, setAvailabe] = useState(true);

  const validateLength = (value, length) => {
    return value.replace(/[^0-9]/g, "").length === length;
  };

  const verify = async () => {
    setLoading(true);

    const verifyData = {
      eventName: "Yoga",
    };

    try {
      const response = await fetch(
        "https://caiobasc.com.br/sendForm/verify.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(verifyData),
        }
      );

      const data = await response.json();

      console.log(data[0].spots_left);

      if (data[0].registration_closed === true) {
        setAvailabe(false);
      }
    } catch (error) {
      console.log("Erro:", error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (formData) => {
    setLoading(true);

    const dataForm = {
      ...formData,
      eventName: "Yoga",
    };

    try {
      const response = await fetch(
        "https://caiobasc.com.br/sendForm/formRegister.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataForm),
        }
      );

      const data = await response.json();

      if (data.success === false) {
        setLoading(false);
        Swal.fire({
          title: "Erro ao enviar formulário",
          html: data.message,
          icon: "error",
        }).then(() => {
          window.location.reload();
        });
        return;
      }
      setLoading(false);

      Swal.fire({
        title: "Formulário enviado com sucesso!",
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: `Erro ao enviar formulário: ${error}`,
        html: "Não foi possível enviar o formulário",
        icon: "error",
      }).then(() => {
        window.location.reload();
      });
    }
  };

  useEffect(() => {
    verify();
  }, []);

  return (
    <Container>
      {loading && <LoadingForm />}
      <ContainerInformations available={available}>
        <Informations>
          <ContentTitle>
            <TextYoga>
              Inscrição para a<br /> <strong>PRÁTICA DE YOGA</strong>
            </TextYoga>
            <Logos>
              <Logo src={LogoCaioba} alt="logo_caioba" />
              <Logo src={LogoYogini} alt="logo_yogini" />
            </Logos>
          </ContentTitle>

          <ContentInfos>
            <label>
              <strong>Sábado (21/09):</strong> das 9h às 10h
            </label>

            <label>
              Aproveite uma prática de yoga no Caioba, proporcionada pela
              Yogini!
            </label>
            <label>
              Não perca essa chance e viva o momento. <br /> Participando você
              concorre a um sorteio de presentes especiais
            </label>
          </ContentInfos>
        </Informations>
        <Content>
          {available === false ? (
            <ContainerDisabled />
          ) : (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="name"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    required
                    label="Nome Completo"
                    variant="standard"
                    value={value}
                    onChange={onChange}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                )}
              />
              <Controller
                control={control}
                name="cpf"
                rules={{
                  required: true,
                  validate: (value) =>
                    validateLength(value, 11) || "CPF incompleto",
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <MaskedTextField
                    mask="999.999.999-99"
                    label="CPF"
                    variant="standard"
                    value={value}
                    onChange={onChange}
                    fullWidth
                    sx={{ mb: 2 }}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />

              <Controller
                control={control}
                name="phone"
                rules={{
                  required: true,
                  validate: (value) =>
                    validateLength(value, 11) || "Telefone incompleto",
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <MaskedTextField
                    mask="(99) 99999-9999"
                    label="Telefone"
                    variant="standard"
                    value={value}
                    onChange={onChange}
                    fullWidth
                    sx={{ mb: 2 }}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />

              <Controller
                control={control}
                name="email"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label="Email"
                    required
                    variant="standard"
                    value={value}
                    onChange={onChange}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                )}
              />

              <ContainerButton>
                <Button type="submit" backgroundColor="#5DA357">
                  Confirmar
                </Button>
              </ContainerButton>
            </Form>
          )}
        </Content>
      </ContainerInformations>
    </Container>
  );
};

export default Yoga;
