// styles.js

import styled from "styled-components";

export const Container = styled.footer`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContentSocialMedia = styled.div`
  width: 100%;
  height: 85px;
  background-color: #f3b000;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 750px) {
    height: 100%;
  }
`;

export const Content = styled.div`
  width: 1200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 58px;

  @media (max-width: 1200px) {
    width: 100%;
    padding: 20px 20px;
  }

  @media (max-width: 750px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const Text = styled.label`
  width: 300px;
  text-align: end;
  font-family: "GothamPro", sans-serif;
  font-weight: 350;
  font-size: 16px;

  @media (max-width: 750px) {
    text-align: center;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 14px;

  span {
    font-size: 12px;
    font-family: "GothamPro", sans-serif;
    font-weight: 350;
  }

  a {
    display: flex;
  }
`;

export const ContentFooter = styled.div`
  width: 100%;
  background-color: #151515;
  height: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "GothamPro", sans-serif;
  font-weight: 350;
  font-size: 16px;

  & :nth-child(2) {
    font-size: 11px;
  }

  a {
    text-decoration: none;
    color: white;
  }
`;
