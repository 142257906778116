import { Container, Content, Logo, SideBarContainer } from "./styles";

import LogoCaioba from "../../assets/logo_caioba_site.svg";
import { FaBars } from "react-icons/fa";
import SideBar from "../sideBar";
import { useState } from "react";

const Header = () => {
  const [sideBarShow, setSideBarShow] = useState(false);

  const showSideBar = () => {
    setSideBarShow(!sideBarShow);
  };

  return (
    <Container>
      <Content>
        <Logo src={LogoCaioba} alt="logo_caioba" />
        <SideBarContainer>
          <FaBars onClick={showSideBar} color="white" />
          {sideBarShow && <SideBar active={setSideBarShow} />}
        </SideBarContainer>
      </Content>
    </Container>
  );
};

export default Header;
