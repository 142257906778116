import {
  CardFor,
  CardOne,
  CardThree,
  CardTwo,
  Container,
  Icon,
  NameEdition,
} from "./styles";

import IconOne from "../../assets/linha_do_tempo/icon_card1.svg";
import IconTwo from "../../assets/linha_do_tempo/icon_card2.svg";
import IconThree from "../../assets/linha_do_tempo/icon_card3.svg";
import IconFor from "../../assets/linha_do_tempo/icon_card4.svg";

const RememberOldEditions = () => {
  return (
    <Container>
      <CardOne>
        <Icon src={IconOne} alt="icone_comandatuba" />
        <NameEdition>EDIÇÃO COMANDATUBA 24</NameEdition>
      </CardOne>
      <CardTwo>
        <Icon src={IconTwo} alt="icone_clubmed" />
        <NameEdition>EDIÇÃO CLUB MED 24</NameEdition>
      </CardTwo>
      <CardThree>
        <Icon src={IconThree} alt="icone_comandatuba" />
        <NameEdition>EDIÇÃO COMANDATUBA 23</NameEdition>
      </CardThree>
      <CardFor>
        <Icon src={IconFor} alt="icone_madrid" />
        <NameEdition>EDIÇÃO ESPECIAL MADRID</NameEdition>
      </CardFor>
    </Container>
  );
};

export default RememberOldEditions;
