import styled from "styled-components";

export const Container = styled.div`
  width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #2a2a2a;
  font-family: "GothamPro", sans-serif;

  label {
    &:nth-child(1) {
      font-size: 20px;
      margin-bottom: 10px;
    }

    &:nth-child(2) {
      font-size: 32px;
      font-weight: 900;
      margin-bottom: 10px;

      @media (max-width: 475px) {
        font-size: 20px;
      }
    }

    &:nth-child(3) {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 30px;

      @media (max-width: 475px) {
        font-size: 20px;
      }
    }

    &:nth-child(5) {
      font-size: 54px;
      margin-bottom: 35px;
      margin-top: 20px;
      font-weight: 900;

      @media (max-width: 475px) {
        font-size: 30px;
      }
    }

    &:nth-child(6) {
      font-size: 19px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    &:nth-child(7) {
      font-size: 19px;
      font-weight: bold;
      margin-bottom: 30px;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
  }
`;

export const Logos = styled.figure`
  display: flex;
  align-items: center;
  gap: 35px;
  margin-top: 20px;

  @media (max-width: 475px) {
    gap: 10px;
  }
`;

export const Logo = styled.img`
  &:nth-child(1) {
    width: 156px;
    height: 77px;
  }

  &:nth-child(2) {
    width: 145px;
    height: 87px;
  }

  @media (max-width: 475px) {
    &:nth-child(1) {
      width: 113px;
      height: 56px;
    }

    &:nth-child(2) {
      width: 105px;
      height: 63px;
    }
  }
`;
