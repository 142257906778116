import styled from "styled-components";

import Background from "../../assets/jogo_das_mães/background.svg";
import BackgroundMobile from "../../assets/jogo_das_mães/background_mobile.svg";

export const Container = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-position: top right;

  @media (max-width: 1230px) {
    padding: 10px 10px;
  }

  @media (max-width: 475px) {
    background-image: url(${BackgroundMobile});
    background-repeat: no-repeat;
    background-position: top right;
  }
`;

export const Content = styled.section`
  width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  @media (max-width: 700px) {
    width: 100%;
  }

  @media (max-width: 475px) {
    margin-top: 15px;
  }
`;

export const ContentInfos = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #2a2a2a;
  font-family: "GothamPro";
  justify-content: center;
  margin-bottom: 10px;
  gap: 5px;

  label {
    &:nth-of-type(1) {
      font-size: 16px;

      @media (max-width: 475px) {
        font-size: 15px;
      }
    }

    &:nth-of-type(2) {
      font-size: 38px;
      font-weight: 900;

      @media (max-width: 475px) {
        font-size: 30px;
      }
    }

    &:nth-of-type(3) {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 15px;
      line-height: 20px;
    }
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logos = styled.figure`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
  gap: 35px;

  @media (max-width: 475px) {
    gap: 10px;
  }
`;

export const Logo = styled.img`
  &:nth-child(1) {
    width: 156px;
    height: 77px;

    @media (max-width: 475px) {
      width: 113px;
      height: 56px;
    }
  }

  &:nth-child(2) {
    width: 150px;
    height: auto;
  }
`;

export const ContainerButton = styled.div`
  width: 300px;
  margin-top: 10px;
`;
