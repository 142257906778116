import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { cpf } from "cpf-cnpj-validator";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

import MaskedTextField from "../maskedField";
import LoadingForm from "../loadingForm";
import ButtonTwo from "../buttonTwo";
import Card from "../card";

import { FaPlusCircle } from "react-icons/fa";
import { IoRemoveCircle } from "react-icons/io5";

import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import {
  Container,
  ContainerButton,
  ContainerHealthCondition,
  ContainerImages,
  ContainerInputs,
  ContainerStepTwo,
  ContainerSteps,
  Content,
  ContentInput,
  ContentLogo,
  Header,
  Logo,
  NameEdition,
  NoDiscount,
  Registered,
  Step,
  TextHealthCondition,
  Title,
  LinkNotAvailable,
} from "./styles";

import LogoCaioba from "../../assets/logo_caioba.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "900px",
  height: "100%",
  maxHeight: "90%",
  bgcolor: "background.paper",
  boxShadow: 20,
  p: 1,
  overflowY: "auto",
  borderRadius: 3,
};

const FormBoaVista = ({ openModal, handleClose }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    unregister,
  } = useForm();

  const { hash } = useParams();

  const [available, setAvailable] = useState(false);

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const [registeredList, setRegisteredList] = useState([{ id: 1 }]);
  const [checkedList, setCheckedList] = useState({});

  const tshirtSizesFather = ["P", "M", "G", "GG"];
  const tshirtSizesMother = ["P", "M", "G", "GG"];
  const ageRegistered = ["5", "6", "7", "8", "9", "10", "11", "12", "13", "14"];

  const sizeTshirtRegistered = [
    "6",
    "8",
    "10",
    "12",
    "PP(adulto)",
    "P(adulto)",
    "M(adulto)",
    "G(adulto)",
  ];

  const sizeShortRegistered = [
    "6",
    "8",
    "10",
    "12",
    "PP(adulto)",
    "P(adulto)",
    "M(adulto)",
    "G(adulto)",
  ];

  const boots = [
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
  ];

  const dateEvent = ["19 e 20 de Outubro"];

  const valueMap = {
    1: 160000,
    2: 304000,
    3: 433600,
  };

  const onSubmit = async (formData, event) => {
    if (step !== 2) {
      event.preventDefault();
      setStep(step + 1);

      return;
    }

    console.log("formulário: ", formData);

    setLoading(true);

    const value = valueMap[registeredList.length] || 0;
    const formattedCPF = formData.cpf.replace(/[.-]/g, "");

    try {
      const postRequest = async (url, body) => {
        const response = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        });

        const data = await response.json();

        if (data.success === false) {
          throw new Error("Erro ao enviar formulário");
        }
        return data; // Retornar a resposta JSON
      };

      const dataFormEmail = {
        ...formData,
        eventName: "Formulário Boa Vista",
        typePayment: "Pix",
      };

      await postRequest(
        "https://caiobasc.com.br/sendForm/sendEmail.php",
        dataFormEmail
      );

      const data = {
        ...formData,
        cpfFormatted: formattedCPF,
        value: value,
      };

      const dataForm = await postRequest(
        "https://caiobasc.com.br/sendForm/sendForm.php",
        data
      );

      const disableLink = await postRequest(
        `https://caiobasc.com.br/sendForm/links/disableLink.php/?hash=${hash}`
      );

      if (disableLink.success === true) {
        if (dataForm && dataForm.secure_url) {
          window.localStorage.setItem("link", dataForm.secure_url);
          window.location.reload();
        } else {
          throw new Error("URL de redirecionamento não encontrada.");
        }
      } else {
        throw new errors("Erro ao atualizar link");
      }
    } catch (error) {
      Swal.fire({
        title: `Erro ao enviar formulário: ${error}`,
        html: "Não foi possível enviar o formulário",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const validateLength = (value, length) => {
    return value.replace(/[^0-9]/g, "").length === length;
  };

  const isValidCPF = (value) => {
    const isValid = cpf.isValid(value);
    return isValid || "CPF inválido";
  };

  const addRegistered = () => {
    if (registeredList.length < 3) {
      setRegisteredList([...registeredList, { id: registeredList.length + 1 }]);
    }
  };

  const removeRegistered = (id) => {
    setRegisteredList((registered) =>
      registered.filter((item) => item.id !== id)
    );
    unregister(`registered[${id - 1}].name`);
    unregister(`registered[${id - 1}].age`);
    unregister(`registered[${id - 1}].gender`);
    unregister(`registered[${id - 1}].tshirt`);
    unregister(`registered[${id - 1}].short`);
    unregister(`registered[${id - 1}].boots`);
    unregister(`registered[${id - 1}].fatherProfession`);
    unregister(`registered[${id - 1}].motherProfession`);
    unregister(`registered[${id - 1}].birthdate`);
    unregister(`registered[${id - 1}].teamRegistered`);
    unregister(`registered[${id - 1}].school`);
    unregister(`registered[${id - 1}].healthCondition`);
  };

  const handleCheckboxChange = (id, checked) => {
    setCheckedList({
      ...checkedList,
      [id]: checked,
    });
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://caiobasc.com.br/sendForm/links/link.php/?hash=${hash}`
      );

      const data = await response.json();

      if (data.status === "disponivel") {
        setAvailable(true);
      }
    } catch (error) {
      console.error("Erro ao buscar dados do link:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  window.addEventListener("load", () => {
    const link = window.localStorage.getItem("link");
    if (link) {
      window.localStorage.removeItem("link");

      window.location.href = link;
    }
  });

  if (available === false) {
    return (
      <LinkNotAvailable>
        <img src="https://caiobasc.com.br/sendForm/image/logo.svg" alt="Logo" />
        <label>Este link não está disponível</label>
      </LinkNotAvailable>
    );
  }

  return (
    <Container>
      {loading && <LoadingForm />}
      <Modal
        keepMounted
        open={openModal}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleSubmit(onSubmit)}>
          <Content>
            <Header>
              <ContentLogo>
                <Logo src={LogoCaioba} />
              </ContentLogo>
              <NameEdition>Boa Vista - 19 e 20 de Outubro 2024</NameEdition>
            </Header>
            <ContainerSteps>
              <Step active={step === 1}>Passo 1</Step>
              <Step active={step === 2}>Passo 2</Step>
            </ContainerSteps>

            {step === 1 && (
              <ContainerInputs>
                <Title>Informações do responsável</Title>
                <ContentInput>
                  <Controller
                    control={control}
                    name="dateEvent"
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <FormControl
                        fullWidth
                        sx={{ mb: 2 }}
                        required
                        style={{ border: "none" }}
                      >
                        <InputLabel>Data do evento</InputLabel>
                        <Select
                          value={value}
                          onChange={onChange}
                          label="Data do Evento"
                          name="Data do Evento"
                        >
                          {dateEvent.map((boot) => (
                            <MenuItem key={boot} value={boot}>
                              {boot}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </ContentInput>
                <ContentInput>
                  <Controller
                    control={control}
                    name="name"
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        required
                        label="Nome Completo"
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                        fullWidth
                        sx={{ mb: 2 }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="email"
                    rules={{
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Endereço de email inválido",
                      },
                    }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        label="Email"
                        required
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                        fullWidth
                        error={!!errors.email}
                        helperText={errors.email ? errors.email.message : null}
                        sx={{ mb: 2 }}
                      />
                    )}
                  />
                </ContentInput>
                <ContentInput>
                  <Controller
                    control={control}
                    name="rg"
                    rules={{
                      required: true,
                      validate: (value) =>
                        validateLength(value, 9) || "RG incompleto",
                    }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <MaskedTextField
                        mask="99.999.999-9"
                        label="RG"
                        value={value}
                        onChange={onChange}
                        fullWidth
                        sx={{ mb: 2 }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="cpf"
                    rules={{
                      required: true,
                      validate: isValidCPF,
                    }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <MaskedTextField
                        mask="999.999.999-99"
                        label="CPF"
                        value={value}
                        onChange={onChange}
                        fullWidth
                        sx={{ mb: 2 }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </ContentInput>
                <ContentInput>
                  <Controller
                    control={control}
                    name="birthdate"
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        label="Data de Nascimento"
                        required
                        type="date"
                        value={value}
                        onChange={onChange}
                        fullWidth
                        sx={{ mb: 2 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="phone"
                    rules={{
                      required: true,
                      validate: (value) =>
                        validateLength(value, 11) || "Telefone incompleto",
                    }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <MaskedTextField
                        mask="(99) 99999-9999"
                        label="Telefone"
                        value={value}
                        onChange={onChange}
                        fullWidth
                        sx={{ mb: 2 }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </ContentInput>
                <ContentInput>
                  <Controller
                    control={control}
                    name="tshirtFather"
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <FormControl fullWidth sx={{ mb: 2 }} required>
                        <InputLabel>Tam. Camiseta (Pai)</InputLabel>
                        <Select
                          name="Tam Camiseta (Pai)"
                          value={value}
                          onChange={onChange}
                          label="Tam. Camiseta (Pai)"
                        >
                          {tshirtSizesFather.map((size) => (
                            <MenuItem key={size} value={size}>
                              {size}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Controller
                    control={control}
                    name="tshirtMother"
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <FormControl fullWidth required>
                        <InputLabel>Tam. Camiseta (Mãe)</InputLabel>
                        <Select
                          name="Tam Camiseta (Mãe)"
                          value={value}
                          onChange={onChange}
                          label="Tam. Camiseta (Mãe)"
                        >
                          {tshirtSizesMother.map((size) => (
                            <MenuItem key={size} value={size}>
                              {size}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </ContentInput>

                <ContentInput>
                  <Controller
                    control={control}
                    name="address"
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        label="Endereço"
                        name="Endereço"
                        required
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                        fullWidth
                        sx={{ mb: 2 }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="cep"
                    rules={{
                      required: true,
                      validate: (value) =>
                        validateLength(value, 8) || "CEP incompleto",
                    }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <MaskedTextField
                        mask="99999-999"
                        label="CEP"
                        value={value}
                        onChange={onChange}
                        fullWidth
                        sx={{ mb: 2 }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </ContentInput>

                <ContentInput>
                  <Controller
                    control={control}
                    name="city"
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        label="Cidade"
                        name="Cidade"
                        required
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                        fullWidth
                        sx={{ mb: 2 }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="state"
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        label="Estado"
                        name="Estado"
                        required
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                        fullWidth
                        sx={{ mb: 2 }}
                      />
                    )}
                  />
                </ContentInput>

                <ContentInput>
                  <Controller
                    control={control}
                    name="block"
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        label="Quadra"
                        name="Quadra"
                        id="Quadra"
                        required
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                        fullWidth
                        sx={{ mb: 2 }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="lot"
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        label="Lote"
                        name="Lote"
                        id="Lote"
                        required
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                        fullWidth
                        sx={{ mb: 2 }}
                      />
                    )}
                  />
                </ContentInput>

                <>
                  <Title>Informações do Inscrito</Title>
                  {registeredList.map((registered, index) => (
                    <div key={registered.id}>
                      <Registered>{registered.id}º Inscrito</Registered>
                      <ContentInput>
                        <Controller
                          control={control}
                          name={`registered[${index}].name`}
                          rules={{ required: true }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              name={`Nome filho ${index}`}
                              label="Nome Completo"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              fullWidth
                              sx={{ mb: 2 }}
                              required
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name={`registered[${index}].age`}
                          rules={{ required: true }}
                          render={({ field: { value, onChange } }) => (
                            <FormControl fullWidth sx={{ mb: 2 }} required>
                              <InputLabel>Idade</InputLabel>
                              <Select
                                name={`Idade filho ${index}`}
                                value={value}
                                onChange={onChange}
                                label="Idade"
                              >
                                {ageRegistered.map((age) => (
                                  <MenuItem key={age} value={age}>
                                    {age}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </ContentInput>
                      <ContentInput>
                        <Controller
                          control={control}
                          name={`registered[${index}].gender`}
                          rules={{ required: true }}
                          render={({ field: { value, onChange } }) => (
                            <FormControl fullWidth sx={{ mb: 2 }} required>
                              <InputLabel>Sexo</InputLabel>
                              <Select
                                name={`Sexo filho ${index}`}
                                value={value}
                                onChange={onChange}
                                label="Sexo"
                              >
                                <MenuItem key="Masculino" value="Masculino">
                                  Masculino
                                </MenuItem>
                                <MenuItem key="Feminino" value="Feminino">
                                  Feminino
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        />

                        <Controller
                          control={control}
                          name={`registered[${index}].tshirt`}
                          rules={{ required: true }}
                          render={({ field: { value, onChange } }) => (
                            <FormControl fullWidth sx={{ mb: 2 }} required>
                              <InputLabel>Tamanho da Camiseta</InputLabel>
                              <Select
                                name={`Tam. camiseta filho ${index}`}
                                value={value}
                                onChange={onChange}
                                label="Tamanho da Camiseta"
                              >
                                {sizeTshirtRegistered.map((size) => (
                                  <MenuItem key={size} value={size}>
                                    {size}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </ContentInput>
                      <ContentInput>
                        <Controller
                          control={control}
                          name={`registered[${index}].short`}
                          rules={{ required: true }}
                          render={({ field: { value, onChange } }) => (
                            <FormControl fullWidth sx={{ mb: 2 }} required>
                              <InputLabel>Tamanho do Short</InputLabel>
                              <Select
                                name={`Tam. short filho ${index}`}
                                value={value}
                                onChange={onChange}
                                label="Tamanho do Short"
                              >
                                {sizeShortRegistered.map((size) => (
                                  <MenuItem key={size} value={size}>
                                    {size}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                        <Controller
                          control={control}
                          name={`registered[${index}].boots`}
                          rules={{ required: true }}
                          render={({ field: { value, onChange } }) => (
                            <FormControl fullWidth sx={{ mb: 2 }} required>
                              <InputLabel>Tamanho da chuteira</InputLabel>
                              <Select
                                name={`Tam. chuteira filho ${index}`}
                                value={value}
                                onChange={onChange}
                                label="Tamanho da chuteira"
                              >
                                {boots.map((boot) => (
                                  <MenuItem key={boot} value={boot}>
                                    {boot}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </ContentInput>

                      <ContentInput>
                        <Controller
                          control={control}
                          name={`registered[${index}].fatherProfession`}
                          rules={{ required: true }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              name={`Profissão pai filho ${index}`}
                              required
                              label="Profissão do Pai"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name={`registered[${index}].motherProfession`}
                          rules={{ required: true }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              required
                              name={`Profissão mãe filho ${index}`}
                              label="Profissão da Mãe"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          )}
                        />
                      </ContentInput>

                      <ContentInput>
                        <Controller
                          control={control}
                          name={`registered[${index}].birthdate`}
                          rules={{ required: true }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              label="Data de Nascimento"
                              name={`Data nascimento filho ${index}`}
                              required
                              type="date"
                              value={value}
                              onChange={onChange}
                              fullWidth
                              sx={{ mb: 2 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />

                        <Controller
                          control={control}
                          name={`registered[${index}].teamRegistered`}
                          rules={{ required: true }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              label="Time do coração"
                              name={`Time do coração filho ${index}`}
                              required
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          )}
                        />
                      </ContentInput>
                      <ContentInput>
                        <Controller
                          control={control}
                          name={`registered[${index}].school`}
                          rules={{ required: true }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              label="Escola que estuda"
                              name={`Escola que estuda filho ${index}`}
                              required
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          )}
                        />
                      </ContentInput>

                      <ContentInput style={{ marginTop: 10 }}>
                        <Controller
                          control={control}
                          name={`registered[${index}].healthCondition`}
                          id={`registered[${index}].healthCondition`}
                          render={({ field: { value, onChange } }) => (
                            <ContainerHealthCondition>
                              <TextHealthCondition>
                                Condição médica especial?
                              </TextHealthCondition>

                              <FormGroup
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={!!checkedList[registered.id]}
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          registered.id,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  }
                                  label="Sim"
                                />
                                {!!checkedList[registered.id] && (
                                  <TextField
                                    label="Qual?"
                                    name={`Condição médica especial filho ${index}`}
                                    required
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                  />
                                )}
                              </FormGroup>
                            </ContainerHealthCondition>
                          )}
                        />
                      </ContentInput>

                      {registered.id === 1 ? (
                        <div
                          style={{
                            width: "100%",
                            marginBottom: 30,
                          }}
                        ></div>
                      ) : (
                        registeredList.length > 1 && (
                          <IoRemoveCircle
                            size={30}
                            color="red"
                            onClick={() => removeRegistered(registered.id)}
                            style={{
                              cursor: "pointer",
                              marginBottom: 10,
                            }}
                          />
                        )
                      )}
                    </div>
                  ))}
                  {registeredList.length < 3 && (
                    <label
                      style={{
                        color: "#151515",
                        fontFamily: "GothamPro",
                        fontWeight: "bold",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        gap: 5,
                        marginBottom: 30,
                      }}
                      onClick={addRegistered}
                    >
                      <FaPlusCircle color="green" /> Adicionar Inscrito
                    </label>
                  )}
                  <ContentInput
                    style={{
                      marginTop: 20,
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 10,
                    }}
                  >
                    <Controller
                      control={control}
                      name="cupom"
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          id="cupom"
                          name="cupom"
                          label="Cupom de desconto"
                          variant="outlined"
                          value={value}
                          onChange={onChange}
                          sx={{ mb: 2, height: 40 }}
                          fullWidth
                        />
                      )}
                    />
                    <NoDiscount>* Descontos não acumulativos!</NoDiscount>
                  </ContentInput>

                  <ContainerButton>
                    <ButtonTwo
                      type="submit"
                      heigth={35}
                      width={300}
                      fontSize={18}
                      letterSpacing={0}
                    >
                      Ir para pagamento
                    </ButtonTwo>
                  </ContainerButton>
                </>
              </ContainerInputs>
            )}

            {step === 2 && (
              <ContainerStepTwo>
                <ContainerImages>
                  <Card onClick={handleSubmit(onSubmit)} />
                </ContainerImages>
                <ButtonTwo
                  width={135}
                  heigth={35}
                  onClick={() => setStep(step - 1)}
                  backgroundColor="#C6C6C6"
                  letterSpacing={0}
                >
                  Voltar
                </ButtonTwo>
              </ContainerStepTwo>
            )}
          </Content>
        </Box>
      </Modal>
    </Container>
  );
};

export default FormBoaVista;
