import styled from "styled-components";

import Background from "../../assets/family_soccer_camp.webp";
import BackgroundMobile from "../../assets/family_soccer_camp_2.webp";

import BackgroumdMap from "../../assets/map_event.svg";
import BackgroundMapMoble from "../../assets/map_event2.svg";

import BackgroundClubMed from "../../assets/bg_inscricao.webp";
import BackgroundClubMedMobile from "../../assets/bg_inscricao_mobile.webp";

import BacgrkoundResortClubmed from "../../assets/clubmed/bg_club_med.webp";
import BackgroundResortClubmedMobile from "../../assets/clubmed/bg_club_med_mobile.webp";
import BackgroundResorteClubmedMobileTwo from "../../assets/clubmed/bg_club_med_mobile2.webp";

export const Container = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ContainerFamilySoccerCamp = styled.section`
  max-width: 100%;
  width: 100%;
  height: 701px;
  background-color: #151515;
  display: flex;
  align-items: center;
  justify-content: end;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  position: relative;
  margin-bottom: 41px;

  @media (max-width: 950px) {
    background-position: 150px;
  }

  @media (max-width: 750px) {
    background-position: 80px;
  }

  @media (max-width: 650px) {
    background-image: url(${BackgroundMobile});
    height: 300px;
    background-position: right;
    margin-bottom: 20px;
  }
`;

export const TextSoccerCamp = styled.span`
  font-size: 60px;
  font-weight: 600;
  font-family: "GothamPro", sans-serif;
  color: white;
  width: 495px;
  height: 216px;
  position: absolute;
  left: 150px;

  @media (max-width: 900px) {
    left: 30px;
  }

  @media (max-width: 750px) {
    left: 20px;
    font-size: 50px;
  }

  @media (max-width: 650px) {
    font-size: 21px;
    width: 177px;
    height: 52px;
  }
`;

export const ContainerEvent = styled.section`
  max-width: 100%;
  width: 100%;
  height: 481px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #151515;
  background-image: url(${BackgroumdMap});
  background-repeat: no-repeat;
  background-position: right;
  margin-bottom: 41px;

  @media (max-width: 950px) {
    height: 100%;
  }

  @media (max-width: 500px) {
    background-image: url(${BackgroundMapMoble});
    background-position: 10px 220px;
    margin-bottom: 20px;
    height: 100%;
  }
`;

export const ContentEvent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: 8%;
  margin-right: 8%;

  @media (max-width: 1250px) {
    width: 100%;
    padding: 20px 20px;
  }

  @media (max-width: 950px) {
    flex-direction: column;
    gap: 30px;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const DateEdition = styled.div`
  width: 477px;
  height: 133px;
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  margin-bottom: 45px;

  & :nth-child(1) {
    font-family: "GothamPro", sans-serif;
    font-weight: 400;
    color: #787878;
    font-size: 34px;

    @media (max-width: 500px) {
      font-size: 22px;
      margin-bottom: 10px;
      display: none;
    }
  }

  & :nth-child(2) {
    font-family: "GothamPro", sans-serif;
    color: white;
    font-weight: 600;
    font-size: 48px;

    span {
      font-size: 50px;
      @media (max-width: 475px) {
        font-size: 30px;
      }
    }

    @media (max-width: 500px) {
      font-size: 37px;
      display: none;
    }
  }

  & :nth-child(3) {
    color: #787878;
    font-size: 15px;
    font-family: "GothamPro", sans-serif;
    display: none;

    @media (max-width: 500px) {
      display: block;
    }
  }

  & :nth-child(4) {
    color: white;
    font-size: 47px;
    font-family: "GothamPro", sans-serif;
    display: none;

    @media (max-width: 500px) {
      display: block;
    }
  }

  @media (max-width: 500px) {
    width: 100%;
    text-align: center;
    justify-content: center;
    margin-top: 37px;
    height: 100%;
    margin-bottom: 0px;
  }
`;

export const NextEvent = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding-top: 56px;

  .ClubMedMobile {
    @media (max-width: 500px) {
      display: none;
    }
  }

  @media (max-width: 950px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    justify-content: center;
    align-items: center;
    padding-top: 0px;
  }
`;

export const NextEventTwo = styled.div`
  height: 100%;
  width: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 950px) {
    width: 100%;
    justify-content: start;
  }

  @media (max-width: 500px) {
    justify-content: center;
    margin-top: 0px;
  }
`;

export const ClubMed = styled.img`
  width: 148px;
  height: 42px;
  position: absolute;
  right: 0px;
  top: 40px;

  @media (max-width: 650px) {
    position: relative;
    top: 0px;
    margin-bottom: 56px;
    margin-top: 10px;
  }
`;

export const ClubMedMobile = styled.img`
  width: 148px;
  height: 42px;
  display: none;

  @media (max-width: 500px) {
    display: block;
    margin-top: 28px;
  }
`;

export const PlayForThem = styled.img`
  width: 444px;
  height: 175px;

  @media (max-width: 500px) {
    display: none;
  }
`;

export const PlayForThemMobile = styled.img`
  width: 248px;
  height: 98px;
  display: none;

  @media (max-width: 500px) {
    display: block;
    margin-bottom: 55px;
  }
`;

export const ContainerAboutUs = styled.section`
  max-width: 100%;
  width: 100%;
  height: 590px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #151515;
  margin-bottom: 58px;

  @media (max-width: 950px) {
    height: 100%;
  }
`;

export const ContentAboutUs = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin-right: 8%;

  @media (max-width: 1250px) {
    width: 100%;
  }

  @media (max-width: 1400px) {
    margin-right: 30px;
  }

  @media (max-width: 950px) {
    flex-direction: column-reverse;
    margin-left: 0px;
    margin-right: 0px;
    gap: 50px;
  }
`;

export const TextAboutUs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  width: 40%;
  color: white;
  height: 100%;
  padding-left: 20px;

  .NameSection {
    @media (max-width: 475px) {
      padding: 20px 20px;
    }
  }

  label {
    font-size: 36px;
    font-family: "GothamPro", sans-serif;
    font-weight: 600;
    width: 409px;
    height: 84px;
    text-align: end;

    @media (max-width: 950px) {
      text-align: center;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 44px;
      font-size: 34px;
    }

    @media (max-width: 1250px) {
      padding: 20px 20px;
    }
  }

  p {
    font-size: 16px;
    font-family: "GothamPro", sans-serif;
    font-weight: 350;
    text-align: end;
    width: 371px;
    height: 73px;

    @media (max-width: 1250px) {
      padding: 20px 20px;
    }

    @media (max-width: 950px) {
      text-align: start;
      width: 100%;
      margin-bottom: 18px;
    }

    @media (max-width: 475px) {
      line-height: 19px;
    }
  }

  cite {
    font-size: 16px;
    font-weight: 500;
    color: #787878;
    font-family: "GothamPro", sans-serif;

    @media (max-width: 1250px) {
      padding: 20px 20px;
    }

    @media (max-width: 950px) {
      width: 100%;
      align-items: flex-start;
    }

    @media (max-width: 475px) {
      margin-top: 20px;
    }
  }

  @media (max-width: 950px) {
    width: 100%;
    align-items: flex-start;
  }

  @media (max-width: 475px) {
    padding-left: 0px;
  }
`;

export const ContainerEditions = styled.section`
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 750px) {
    height: 100%;
  }
`;

export const ContentEditions = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 17.5px;
  font-weight: 400;
  font-family: "GothamPro", sans-serif;
  color: #7d7d7d;
  margin-bottom: 55px;
  gap: 15px;

  & :nth-child(1) {
    font-size: 36px;
    font-weight: 600;
    font-family: "GothamPro", sans-serif;
    color: #000000;

    @media (max-width: 475px) {
      margin-bottom: 18px;
      font-size: 25px;
    }
  }

  & :nth-child(2) {
    letter-spacing: 6px;

    @media (max-width: 475px) {
      font-size: 12px;
      line-height: 15px;
    }
  }

  @media (max-width: 1250px) {
    width: 100%;
    padding: 20px 20px;
    text-align: center;
  }

  @media (max-width: 475px) {
    margin-bottom: 20px;
  }
`;

export const ContainerEventClubMed = styled.section`
  max-width: 100%;
  width: 100%;
  height: 598px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${BackgroundClubMed});
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  border-top: 13px solid #f3b000;
  background-color: #151515;
  padding-top: 20px;
  padding-bottom: 20px;

  @media (max-width: 1350px) {
    width: 100%;
    padding: 20px 20px;
  }

  @media (max-width: 1400px) {
    background-position: 35%;
  }

  @media (max-width: 1024px) {
    background-position: 45%;
  }

  @media (max-width: 900px) {
    background-position: 55%;
  }

  @media (max-width: 475px) {
    height: 100%;
    background-color: #151515;
    background-position: bottom;
    align-items: start;
    background-image: url(${BackgroundClubMedMobile});
    background-size: contain;
    padding-bottom: 250px;
  }
`;

export const ContentEventClubMed = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-family: "GothamPro", sans-serif;
  justify-content: center;
  margin-left: 8%;
  color: white;
  padding-top: 40px;

  & :nth-child(2) {
    font-size: 20px;
    color: #7d7d7d;
    margin-top: 26px;
    letter-spacing: 6px;
    margin-bottom: 10px;
  }

  & :nth-child(3) {
    font-size: 60px;
    color: white;
    @media (max-width: 475px) {
      font-size: 35px;
      text-align: center;
    }
  }

  & :nth-child(4) {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 50px;
    margin-top: 50px;
    width: 100%;
    max-width: 600px;

    @media (max-width: 800px) {
      width: 100%;
    }

    @media (max-width: 475px) {
      font-size: 18px;
    }
  }

  & :nth-child(5) {
    font-size: 20px;
    font-weight: 500;
    color: #f3b000;
    width: 100%;
    max-width: 600px;
    height: 100%;

    @media (max-width: 800px) {
      width: 100%;
    }

    @media (max-width: 475px) {
      font-size: 18px;
    }
  }

  @media (max-width: 1250px) {
    width: 100%;
  }

  @media (max-width: 950px) {
    margin-left: 0px;
  }
  @media (max-width: 475px) {
    height: 50%;
    align-items: center;
    margin-bottom: 30px;
  }
`;

export const ClubMedEvent = styled.img`
  width: 329px;
  height: 122px;
`;

export const ContainerRegistrations = styled.section`
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #151515;
`;

export const ContentRegistrations = styled.div`
  width: 1200px;
  height: 100%;
  padding-top: 88px;
  padding-bottom: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  @media (max-width: 1250px) {
    width: 100%;
    padding: 20px 20px;
  }
`;

export const ContentTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;

  & :nth-child(2) {
    font-size: 29px;
    font-weight: 600;
    font-family: "GothamPro", sans-serif;
    color: white;

    @media (max-width: 475px) {
      font-size: 20px;
    }
  }
`;

export const ContainerCards = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 55px;
  margin-bottom: 55px;

  @media (max-width: 1125px) {
    justify-items: center;
    gap: 10px;
    align-content: center;
  }

  @media (max-width: 760px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 475px) {
    margin-bottom: 0px;
  }
`;

export const IconRegistrations = styled.img`
  width: 45px;
  height: 37px;
`;

export const DescriptionRegistrations = styled.label`
  font-size: 24px;
  font-weight: 600;
  font-family: "GothamPro", sans-serif;
  color: white;
  width: 85%;
  text-align: center;
  margin-top: 55px;
  margin-bottom: 55px;

  span {
    color: #f3b000;
  }

  @media (max-width: 1200px) {
    font-size: 20px;
  }

  @media (max-width: 920px) {
    width: 100%;
  }

  @media (max-width: 475px) {
    font-size: 16px;
  }
`;

export const ContainerButton = styled.div`
  width: 427px;
  height: 71px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 450px) {
    width: 100%;
  }

  & :nth-child(2) {
    cursor: pointer;
    @media (max-width: 475px) {
      font-size: 15px;
      letter-spacing: 0px;
    }
  }
`;

export const ContainerClubMedResort = styled.section`
  max-width: 100%;
  width: 100%;
  height: 563px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(${BacgrkoundResortClubmed});
  background-repeat: no-repeat;
  background-position: right;
  background-color: #151515;
  background-size: cover;

  @media (max-width: 1350px) {
    width: 100%;
  }

  @media (max-width: 1024px) {
    background-image: url(${BackgroundResorteClubmedMobileTwo});
    height: auto;
    padding-bottom: 20px;
  }

  @media (max-width: 475px) {
    height: auto;
    background-size: 500px;
    background-position: top;
    background-image: url(${BackgroundResortClubmedMobile});
  }
`;

export const ContentClubMedResort = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 8%;
  gap: 20px;

  p {
    font-weight: 350;
    font-size: 20px;
    font-family: "GothamPro", sans-serif;
    color: white;
    width: 50%;

    @media (max-width: 1024px) {
      width: 70%;
    }

    @media (max-width: 900px) {
      width: 100%;
    }

    @media (max-width: 475px) {
      font-size: 18px;
    }
  }

  @media (max-width: 1250px) {
    width: 100%;
    padding: 20px 20px;
  }
`;

export const ClubMedResort = styled.img`
  width: 253px;
  height: 73px;
  margin-top: 73px;
  margin-bottom: 70px;

  @media (max-width: 1024px) {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  @media (max-width: 475px) {
    margin: 75px auto;
  }
`;

export const ContainerTable = styled.section`
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 61px;
  flex-direction: column;
  background-color: #151515;
  padding-bottom: 57px;

  label {
    font-size: 24px;
    font-weight: 600;
    font-family: "GothamPro", sans-serif;
    color: #f3b000;
    margin-bottom: 88px;

    @media (max-width: 475px) {
      font-size: 15px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1350px) {
    width: 100%;
    padding: 20px 20px;
  }
`;

export const ContentTable = styled.div`
  width: 1200px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1250px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
`;

export const TextDiscount = styled.span`
  margin-top: 20px;
  font-size: 24px;
  font-family: "GothamPro", sans-serif;
  color: white;
  font-weight: 100;

  @media (max-width: 620px) {
    font-size: 18px;
  }

  @media (max-width: 475px) {
    font-size: 16px;
  }
`;

export const ContentType = styled.div`
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: start;

  @media (max-width: 1250px) {
    width: 100%;
  }

  @media (max-width: 1170px) {
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const Type = styled.img`
  width: 561px;
  height: 100%;
  margin-bottom: 41px;

  @media (max-width: 580px) {
    width: 100%;
  }
`;

export const ContentInstructions = styled.div`
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 41px;
  padding-right: 40px;
  padding-left: 30px;

  @media (max-width: 1250px) {
    width: 100%;
  }

  @media (max-width: 1170px) {
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  @media (max-width: 475px) {
    align-items: start;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
  }
`;

export const Line = styled.div`
  height: 2px;
  background-color: #f3b000;
  width: 888px;
  margin-top: 41px;
  margin-bottom: 41px;

  @media (max-width: 900px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const Instruction = styled.img`
  width: 488px;
  height: 100%;

  @media (max-width: 580px) {
    width: 100%;
    height: 100%;
  }
`;

export const ContentPaymentAndCheckin = styled.div`
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 41px;
  padding-left: 30px;

  & :nth-child(1) {
    @media (max-width: 580px) {
      width: 100%;
      height: 100%;
    }
  }

  & :nth-child(2) {
    @media (max-width: 580px) {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 1250px) {
    width: 100%;
  }

  @media (max-width: 1050px) {
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }

  @media (max-width: 475px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const Infos = styled.img`
  width: 530px;
  height: 100%;
`;

export const IconArrow = styled.img`
  position: absolute;
  left: 150px;
  bottom: 120px;
  width: 46px;
  height: 94px;

  @media (max-width: 900px) {
    left: 30px;
  }

  @media (max-width: 650px) {
    bottom: 30px;
    width: 45px;
    height: 45px;
    left: 10px;
  }
`;

export const ContainerRegisteredButton = styled.div`
  width: 100%;
  max-width: 427px;

  & :nth-child(2) {
    @media (max-width: 475px) {
      font-size: 15px;
      letter-spacing: 0px;
    }
  }
`;

export const ContainerButtonRegisred = styled.div`
  width: 100%;
  max-width: 356px;

  & :nth-child(2) {
    @media (max-width: 475px) {
      font-size: 18px;
      letter-spacing: 0px;
    }
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const ContainerButtonRegisteredMobile = styled.div`
  display: none;

  @media (max-width: 500px) {
    display: block;
    margin-bottom: 37px;
  }
`;

export const Table = styled.img`
  width: 100%;
  height: auto;

  @media (max-width: 675px) {
    display: none;
  }
`;

export const TableTwo = styled.img`
  width: 100%;
  height: auto;
  display: none;

  @media (max-width: 675px) {
    display: block;
  }
`;
