import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import Swal from "sweetalert2";

import LoadingForm from "../../components/loadingForm";
import MaskedTextField from "../../components/maskedField";
import Button from "../../components/button";

import { TextField } from "@mui/material";

import {
  Container,
  ContainerButton,
  Content,
  ContentInfos,
  Form,
  Logo,
  Logos,
} from "./styles";

import LogoCaioba from "../../assets/jogo_dos_pais/logo.svg";

import LogoMelita from "../../assets/jogo_das_mães/logo_melita.svg";

const MothersGame = () => {
  const { control, handleSubmit } = useForm();

  const [loading, setLoading] = useState(false);

  const validateLength = (value, length) => {
    return value.replace(/[^0-9]/g, "").length === length;
  };

  const onSubmit = async (formData) => {
    setLoading(true);

    const dataForm = {
      ...formData,
      eventName: "Jogo das Mães",
    };

    try {
      const response = await fetch(
        "https://caiobasc.com.br/sendForm/formRegister.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataForm),
        }
      );

      const data = await response.json();

      if (data.success === false) {
        setLoading(false);
        Swal.fire({
          title: "Erro ao enviar formulário",
          html: data.message,
          icon: "error",
        }).then(() => {
          window.location.reload();
        });

        return;
      }

      setLoading(false);

      Swal.fire({
        title: "Formulário enviado com sucesso!",

        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: `Erro ao enviar formulário: ${error}`,
        html: "Não foi possível enviar o formulário",
        icon: "error",
      }).then(() => {
        window.location.reload();
      });
    }
  };

  return (
    <Container>
      {loading && <LoadingForm />}
      <Content>
        <ContentInfos>
          <label>Inscrição para o </label>
          <label>JOGO DAS MÃES</label>
          <Logos>
            <Logo src={LogoCaioba} alt="logo_caioba" />
            <Logo src={LogoMelita} alt="logo_melita" />
          </Logos>
          <label>
            É hora de mostrar o talento que os nossos craques herdaram!
          </label>
          <label>Mostre para seu filho como é gostoso competir!</label>
        </ContentInfos>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <TextField
                required
                label="Nome Completo da Jogadora"
                variant="standard"
                value={value}
                onChange={onChange}
                fullWidth
                sx={{ mb: 2 }}
              />
            )}
          />

          <Controller
            control={control}
            name="email"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <TextField
                label="Email"
                required
                variant="standard"
                value={value}
                onChange={onChange}
                fullWidth
                sx={{ mb: 2 }}
              />
            )}
          />

          <Controller
            control={control}
            name="phone"
            rules={{
              required: true,
              validate: (value) =>
                validateLength(value, 11) || "Telefone incompleto",
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <MaskedTextField
                mask="(99) 99999-9999"
                label="Celular"
                variant="standard"
                value={value}
                onChange={onChange}
                fullWidth
                sx={{ mb: 2 }}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />

          <ContainerButton>
            <Button type="submit" backgroundColor="#E24D3C">
              CONFIRMAR
            </Button>
          </ContainerButton>
        </Form>
      </Content>
    </Container>
  );
};

export default MothersGame;
