import {
  Container,
  Content,
  ContentFooter,
  ContentSocialMedia,
  SocialMedia,
  Text,
} from "./styles";

import { IoLogoInstagram } from "react-icons/io5";
import { FaFacebook } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { CiMail } from "react-icons/ci";

const Footer = () => {
  const date = new Date();
  const currentDate = date.getFullYear();

  return (
    <Container id="contato">
      <ContentSocialMedia>
        <Content>
          <Text>
            Para mais informações, entre em contato com a nossa equipe
          </Text>
          <SocialMedia>
            <a
              href="https://www.instagram.com/caiobasoccercamp/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <IoLogoInstagram size={25} color="#151515" />
            </a>
            <a
              href="https://www.facebook.com/caiobasoccercamp/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <FaFacebook size={25} color="#151515" />
            </a>
            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <a
                href="https://wa.me/+5511930590732"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="WhatsApp"
              >
                <FaWhatsapp size={25} color="#151515" />
              </a>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <a
                href="mailto:inscricoes@caiobasc.com.br"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Email"
              >
                <CiMail size={25} color="#151515" />
              </a>
              <span>inscricoes@caiobasc.com.br</span>
            </div>
          </SocialMedia>
        </Content>
      </ContentSocialMedia>

      <ContentFooter>
        <label>&copy; {currentDate} Caioba Soccer Camp</label>
        <label>
          Desenvolvido Por{" "}
          <a
            href="https://ypb.com.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            YPB MKT Digital
          </a>
        </label>
      </ContentFooter>
    </Container>
  );
};

export default Footer;
