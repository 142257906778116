import { useState } from "react";

import Footer from "../../components/footer";
import Header from "../../components/header";
import NameSection from "../../components/nameSection";
import ButtonTwo from "../../components/buttonTwo";
import RememberOldEditions from "../../components/rememberOldEditions";
import FormData from "../../components/form";
import CardRegistration from "../../components/cardRegistration";
import CustomVideoPlayer from "../../components/video";
import ScrollingBackground from "../../components/line";

import {
  ClubMed,
  ClubMedEvent,
  ClubMedMobile,
  ClubMedResort,
  Container,
  ContainerAboutUs,
  ContainerButton,
  ContainerButtonRegisred,
  ContainerButtonRegisteredMobile,
  ContainerCards,
  ContainerClubMedResort,
  ContainerEditions,
  ContainerEvent,
  ContainerEventClubMed,
  ContainerFamilySoccerCamp,
  ContainerRegisteredButton,
  ContainerRegistrations,
  ContainerTable,
  ContentAboutUs,
  ContentClubMedResort,
  ContentEditions,
  ContentEvent,
  ContentEventClubMed,
  ContentInstructions,
  ContentPaymentAndCheckin,
  ContentRegistrations,
  ContentTable,
  ContentTitle,
  ContentType,
  DateEdition,
  DescriptionRegistrations,
  IconArrow,
  IconRegistrations,
  Infos,
  Instruction,
  Line,
  NextEvent,
  NextEventTwo,
  PlayForThem,
  PlayForThemMobile,
  Table,
  TableTwo,
  TextAboutUs,
  TextDiscount,
  TextSoccerCamp,
  Type,
} from "./styles";

import LogoClubMed from "../../assets/logo_clubmed.svg";
import VectorPlayForThem from "../../assets/jogue_por_eles.svg";
import ImageClubMedEvent from "../../assets/logo_event_clubmed.svg";
import RegistrationsIcon from "../../assets/icons_registrations.svg";
import ImageClubMedEventTwo from "../../assets/logo_clubmed2.svg";

import Iconbutton from "../../assets/icons/icon_button.svg";
import IconButtonTwo from "../../assets/icons/icon_button2.svg";

import TypeHotelOne from "../../assets/type1.svg";
import TypeHotelTwo from "../../assets/type2.svg";

import InstructionOne from "../../assets/instrucao1.svg";
import InstructionTwo from "../../assets/instrucao2.svg";

import InfoOne from "../../assets/forma_de_pagamento.svg";
import InfoTwo from "../../assets/checkin.svg";

import ImageArrow from "../../assets/icons/arrow.svg";

import ImageTable from "../../assets/tabela.webp";
import ImageTableTwo from "../../assets/tabela2.svg";

const Home = () => {
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Container>
      <FormData openModal={openModal} handleClose={handleClose} />
      <Header />
      <ContainerFamilySoccerCamp>
        <TextSoccerCamp>
          O <span style={{ color: "#F3B000" }}>Maior</span> Family Soccer Camp
          do mundo.
        </TextSoccerCamp>
        <IconArrow src={ImageArrow} alt="icone_seta" />
      </ContainerFamilySoccerCamp>
      <ContainerEvent>
        <ContentEvent>
          <NextEvent>
            <NameSection
              textOne="PRÓXIMO"
              textTwo="EVENTO"
              className="next_event"
            />
            <ClubMedMobile
              src={LogoClubMed}
              alt="logo_club_med"
              className="ClubMedMobile"
            />
            <DateEdition>
              <label>SETEMBRO</label>
              <label>
                13 à 15 <span style={{ color: "#F3B000" }}>e</span> 20 à 22
              </label>

              <label>De 13 a 15 | 20 a 22</label>
              <label>SETEMBRO</label>
            </DateEdition>
            <ContainerButtonRegisred>
              <ButtonTwo
                icon={true}
                iconImage={Iconbutton}
                width="100%"
                onClick={() =>
                  document
                    .getElementById("inscricoes")
                    .scrollIntoView({ behavior: "smooth" })
                }
              >
                <label>INSCREVA-SE</label>
              </ButtonTwo>
            </ContainerButtonRegisred>
          </NextEvent>
          <NextEventTwo>
            <PlayForThem src={VectorPlayForThem} alt="frase_jogue_por_eles" />
            <PlayForThemMobile src={VectorPlayForThem} />
            <ClubMed src={LogoClubMed} alt="logo_club_med" />
            <ContainerButtonRegisteredMobile>
              <ButtonTwo
                icon={true}
                iconImage={Iconbutton}
                width="100%"
                onClick={() =>
                  document
                    .getElementById("inscricoes")
                    .scrollIntoView({ behavior: "smooth" })
                }
              >
                <label>INSCREVA-SE</label>
              </ButtonTwo>
            </ContainerButtonRegisteredMobile>
          </NextEventTwo>
        </ContentEvent>
      </ContainerEvent>

      <ContainerAboutUs id="sobrenos">
        <ContentAboutUs>
          <CustomVideoPlayer />
          <TextAboutUs>
            <NameSection
              textOne="SOBRE"
              textTwo="NÓS"
              style={{ justifyContent: "end" }}
              className="NameSection"
            />
            <label>
              Bem-vindo ao{" "}
              <span style={{ color: "#F3B000" }}>Caioba Soccer Camp!</span>
            </label>

            <p>
              "Aqui, transformamos sonhos em realidade através do futebol. Nossa
              missão é unir e fortalecer famílias, promovendo valores como
              respeito, igualdade e espírito de equipe."
            </p>
            <cite>- Caio Ribeiro</cite>
          </TextAboutUs>
        </ContentAboutUs>
      </ContainerAboutUs>

      <ContainerEditions id="linhadotempo">
        <ContentEditions>
          <label>Reviva momentos marcantes das nossas edições passadas.</label>
          <label>
            EXPLORE NOSSA HISTÓRIA COM UMA LINHA DO TEMPO INTERATIVA
          </label>
        </ContentEditions>
        <RememberOldEditions />
      </ContainerEditions>

      <ScrollingBackground />

      <ContainerEventClubMed>
        <ContentEventClubMed>
          <ClubMedEvent src={ImageClubMedEvent} alt="logo_clubmed" />
          <label>SETEMBRO DE 2024</label>
          <label>
            13 à 15 <span style={{ color: "#F3B000" }}>e</span> 20 à 22
          </label>

          <label>
            O melhor fim de semana da sua família começa agora! Amarre as
            chuteiras, olho na bola e bora jogar!
          </label>
          <label>
            *O Caioba Edição Club Med acontece em dois finais de semana, escolha
            a melhor data após seguir com a inscrição.
          </label>
        </ContentEventClubMed>
      </ContainerEventClubMed>

      <ContainerRegistrations id="inscricoes">
        <ContentRegistrations>
          <ContentTitle>
            <IconRegistrations src={RegistrationsIcon} alt="icon_inscricao" />
            <label>INSCRIÇÕES DO CAIOBA</label>
          </ContentTitle>

          <ContainerCards>
            <CardRegistration amount="1 INSCRIÇÃO" amountRegistration={1} />
            <CardRegistration amount="2 INSCRIÇÕES" amountRegistration={2} />
            <CardRegistration amount="3 INSCRIÇÕES" amountRegistration={3} />
          </ContainerCards>

          <DescriptionRegistrations>
            <span>*</span>O desconto para segunda e terceira inscrições é válido
            <span> somente para irmãos</span>. <br /> <span>**</span>Descontos
            não acumulativos.
          </DescriptionRegistrations>

          <ContainerRegisteredButton>
            <ButtonTwo icon={false} fontSize={19} width="100%">
              INSCRIÇÕES ENCERRADAS
            </ButtonTwo>
          </ContainerRegisteredButton>

          <DescriptionRegistrations>
            <span>*</span>Para participar, é necessário fazer a inscrição, além
            das <span>reservas de hotel</span> e <span>passagens aéreas</span>,
            quando necessário.
          </DescriptionRegistrations>

          <ContainerButton>
            <ButtonTwo
              icon={true}
              iconImage={IconButtonTwo}
              backgroundColor="#222221"
              width="100%"
              fontSize={19}
              lineHeight={"24px"}
              onClick={() => setIsVisible(true)}
            >
              <label>VEJA OS VALORES DE HOSPEDAGEM</label>
            </ButtonTwo>
          </ContainerButton>
        </ContentRegistrations>
      </ContainerRegistrations>

      {isVisible && (
        <>
          <ContainerClubMedResort>
            <ContentClubMedResort>
              <ClubMedResort src={ImageClubMedEventTwo} alt="logo_clubmed" />
              <p>
                O <strong>Resort Club Med Lake Paradise</strong>, em São Paulo,
                é o destino perfeito para recarregar as energias com férias All
                Inclusive.
              </p>
              <p>
                A apenas uma hora da capital, oferece relaxamento na piscina,
                vela na represa Taiaçupeba e golfe na Escola de Golfe.{" "}
                <strong>Ideal para famílias e casais</strong>, proporciona uma
                escapada de fim de semana em meio à natureza com diversas
                atividades aquáticas e terrestres.
              </p>
              <p>
                O conceito Premium All Inclusive inclui acomodações, refeições e
                uma variedade de esportes e atividades para todas as idades.
              </p>
            </ContentClubMedResort>
          </ContainerClubMedResort>

          <ContainerTable>
            <ContentTable>
              <Table src={ImageTable} alt="tabela" />
              <TableTwo src={ImageTableTwo} alt="tabela" />
            </ContentTable>
            <TextDiscount>
              <label>* </label>
              Valores acima já estão contemplados com o % de desconto da
              política de crianças
            </TextDiscount>
            <Line />
            <ContentType>
              <Type alt="tipo_de_hotel_1" src={TypeHotelOne} />
              <Type alt="tipo_de_hotel_2" src={TypeHotelTwo} />
            </ContentType>

            <ContentInstructions>
              <Instruction alt="instrução1" src={InstructionOne} />
              <Instruction alt="instrução2" src={InstructionTwo} />
            </ContentInstructions>
            <Line />

            <ContentPaymentAndCheckin>
              <Infos alt="forma_de_pagamento" src={InfoOne} />
              <Infos alt="checkin" src={InfoTwo} />
            </ContentPaymentAndCheckin>

            <label>
              Após a inscrição, aguarde o e-mail da agência para prosseguir.
            </label>
            <ContainerButton>
              <ButtonTwo
                icon={true}
                iconImage={IconButtonTwo}
                backgroundColor="#222221"
                onClick={() => setIsVisible(false)}
                width="100%"
              >
                FECHAR TABELA
              </ButtonTwo>
            </ContainerButton>
          </ContainerTable>
        </>
      )}

      <Footer />
    </Container>
  );
};

export default Home;
