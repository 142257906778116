import styled from "styled-components";

import ImageCardOne from "../../assets/linha_do_tempo/ltempo01.webp";
import ImageCardTwo from "../../assets/linha_do_tempo/ltempo02.webp";
import ImageCardThree from "../../assets/linha_do_tempo/ltempo03.webp";
import ImageCardFor from "../../assets/linha_do_tempo/ltempo04.webp";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 750px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
`;

export const Icon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;

  @media (max-width: 475px) {
    width: 80px;
    height: 80px;
  }
`;

export const NameEdition = styled.label`
  font-size: 14px;
  letter-spacing: 6px;
  font-family: "GothamPro", sans-serif;
  font-weight: 400;
  color: #f3b000;
  position: absolute;
  bottom: 10px;
  text-align: center;
  width: 100%;

  @media (max-width: 1400px) {
    font-size: 12px;
  }

  @media (max-width: 1200px) {
    letter-spacing: 3px;
  }

  @media (max-width: 475px) {
    letter-spacing: 1px;
  }
`;

const Card = styled.div`
  width: 25%;
  height: 479px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;

  @media (max-width: 1400px) {
    height: 350px;
  }

  @media (max-width: 1024px) {
    height: 270px;
  }

  @media (max-width: 750px) {
    width: 100%;
  }

  @media (max-width: 670px) {
    height: 200px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    background-position: inherit;
    background-repeat: inherit;
    background-size: inherit;
    filter: grayscale(100%) contrast(100%) brightness(100%);
    transition: filter 0.5s ease;
  }

  &:hover::before {
    filter: grayscale(0%) contrast(110%) brightness(100%);
  }
`;

export const CardOne = styled(Card)`
  background-image: url(${ImageCardOne});
`;

export const CardTwo = styled(Card)`
  background-image: url(${ImageCardTwo});
`;

export const CardThree = styled(Card)`
  background-image: url(${ImageCardThree});
`;

export const CardFor = styled(Card)`
  background-image: url(${ImageCardFor});
`;
