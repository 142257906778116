import React from "react";
import InputMask from "react-input-mask";

import TextField from "@mui/material/TextField";

const MaskedTextField = ({
  mask,
  variant = "outlined",
  placeholder,
  ...props
}) => {
  return (
    <InputMask mask={mask} {...props} placeholder={placeholder}>
      {(inputProps) => (
        <TextField {...inputProps} fullWidth required variant={variant} />
      )}
    </InputMask>
  );
};

export default MaskedTextField;
