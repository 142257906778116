import styled from "styled-components";

import Background from "../../assets/desafio-pai-e-filho/background.svg";
import BackgroundMobile from "../../assets/desafio-pai-e-filho/background_mobile.svg";

export const Container = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-position: top right;

  @media (max-width: 1230px) {
    padding: 10px 10px;
  }

  @media (max-width: 475px) {
    background-image: url(${BackgroundMobile});
    background-repeat: no-repeat;
    background-position: top right;
  }
`;

export const ContainerInformations = styled.section`
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  @media (max-width: 475px) {
    margin-top: 15px;
  }
`;

export const Informations = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin-bottom: 30px;
`;

export const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
`;

export const Form = styled.form`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const ContentInfos = styled.div`
  width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #2a2a2a;
  font-family: "GothamPro", sans-serif;
  font-size: 20px;

  & :nth-child(2) {
    font-size: 38px;
    font-weight: 900;

    @media (max-width: 475px) {
      font-size: 20px;
    }
  }

  & :nth-child(3) {
    font-size: 17px;
    margin-top: 30px;
  }

  & :nth-child(4) {
    font-size: 17px;
    margin-bottom: 40px;
  }

  & :nth-child(5) {
    font-size: 19px;
    font-weight: bold;
  }

  @media (max-width: 1150px) {
    width: 100%;
  }
`;

export const Logos = styled.figure`
  display: flex;
  align-items: center;
  gap: 35px;
  margin-top: 20px;

  @media (max-width: 475px) {
    gap: 10px;
  }
`;

export const Logo = styled.img`
  &:nth-child(1) {
    width: 156px;
    height: 77px;
  }

  &:nth-child(2) {
    width: 145px;
    height: 87px;
  }

  @media (max-width: 475px) {
    &:nth-child(1) {
      width: 113px;
      height: 56px;
    }

    &:nth-child(2) {
      width: 105px;
      height: 63px;
    }
  }
`;
