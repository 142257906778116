import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./home";
import BeachTennisFemale from "./beachTennisFemale";
import BeachTennisMale from "./beachTennisMale";
import MothersGame from "./mother'sGame";
import FathersGame from "./father'sGame";
import Yoga from "./yoga";
import FatherSonChallenge from "./fatherAndSon";
import TrainingEvolves from "./trainingEvolves";
import BoaVista from "./boaVista";
import MelittaCourse from "./melitaCourse";

const Root = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/beach-tennis-feminino" element={<BeachTennisFemale />} />
        <Route path="/beach-tennis-masculino" element={<BeachTennisMale />} />
        <Route path="/jogo-das-maes" element={<MothersGame />} />
        <Route path="/jogo-dos-pais" element={<FathersGame />} />
        <Route path="/desafio-pai-e-filho" element={<FatherSonChallenge />} />
        <Route path="/yoga" element={<Yoga />} />
        <Route path="/treino-evoluir" element={<TrainingEvolves />} />
        <Route path="/curso-melitta" element={<MelittaCourse />} />
        <Route path="/boa-vista/:hash" element={<BoaVista />} />
      </Routes>
    </Router>
  );
};

export default Root;

//<Route path="/clinica-de-tenis" element={<TennisClinic />} />
