import styled from "styled-components";

import Background from "../../assets/yoga/background.svg";
import BackgroundMobile from "../../assets/yoga/background_mobile.svg";

export const Container = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-position: top right;

  @media (max-width: 475px) {
    background-image: url(${BackgroundMobile});
    background-repeat: no-repeat;
    background-position: top right;
  }
`;

export const ContainerInformations = styled.section`
  max-width: 600px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;

  @media (max-width: 1200px) {
    width: 100%;
    padding: 10px 10px;
  }

  @media (max-width: 475px) {
    margin-top: 15px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
`;

export const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Informations = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const ContentTitle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: start;
  margin-bottom: 17px;
  flex-direction: column;
`;

export const TextYoga = styled.label`
  font-size: 20px;
  font-weight: 400;
  color: #2a2a2a;
  font-family: "GothamPro", sans-serif;

  strong {
    font-size: 38px;
    font-weight: 900;

    @media (max-width: 475px) {
      font-size: 20px;
    }
  }
`;

export const ContentInfos = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: "GothamPro", sans-serif;
  color: #2a2a2a;

  & :nth-child(2) {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 900;
    margin-top: 10px;
  }
  & :nth-child(3) {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  @media (max-width: 1150px) {
    margin-top: 30px;
  }
`;

export const Logos = styled.figure`
  display: flex;
  align-items: center;
  gap: 35px;
  margin-top: 20px;

  @media (max-width: 475px) {
    gap: 10px;
  }
`;

export const Logo = styled.img`
  &:nth-child(1) {
    width: 156px;
    height: 77px;
  }

  &:nth-child(2) {
    width: 145px;
    height: 87px;
  }

  @media (max-width: 475px) {
    &:nth-child(1) {
      width: 113px;
      height: 56px;
    }

    &:nth-child(2) {
      width: 105px;
      height: 63px;
    }
  }
`;

export const ContainerButton = styled.div`
  width: 300px;
  margin-top: 10px;
`;
